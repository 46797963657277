<template>
    <div>
        <div class="table_content">
            <!-- 表格内容 -->
            <commonTitle></commonTitle>
            <div class="search">
                <el-form ref="form" :model="form" label-width="75px" :inline="true">
                    <el-form-item label="搜索条件:">
                        <el-select v-model="searchKey" placeholder="请选择" size="mini">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="searchValue" placeholder="请输入搜索值" size="mini"></el-input>
                    </el-form-item>
                    <button @click.prevent="common()" style="cursor: pointer">搜索</button>
                </el-form>
            </div>
            <el-card>
                <el-table
                        :data="tableData"
                        v-loading="loading"
                        style="width:100%"
                        element-loading-text="拼命加载中"
                        
                        :default-sort="{prop: 'settingTime', order: 'descending'}"
                        :stripe="true"
                >


                    <el-table-column
                            prop="villageName"
                            label="小区名称"
                            show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                            prop="towerNum"
                            label="楼幢号"
                            show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            prop="unitName"
                            label="单元名称"
                            show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            prop="houseNum"
                            label="户室编号"
                            width="120px">
                    </el-table-column>
                    <el-table-column
                            prop="coveredarea"
                            label="建筑面积"
                            show-overflow-tooltip
                    >
                    </el-table-column>

                    <el-table-column
                            prop="housPurchase"
                            label="购房款"

                    >
                    </el-table-column>
                    <el-table-column
                            prop="ownerName"
                            label="业主"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="houseType"
                            label="户室类型"
                            :formatter="formatterHouseType"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="state"
                            label="房屋状态"
                            :formatter="formatterHouseState"
                    >
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            label="操作"
                            width="120">
                        <template slot-scope="scope">
                            <el-button
                                    type="text"
                                    size="mini"
									:disabled="scope.row.houseacctBal >0?true:false"
                                    @click.prevent="bindHouseOwner(scope.row)"
                            >
                                绑定
                            </el-button>

                            <el-button
                                    type="text"
                                    size="mini"
                                    v-show="isShowChangeHouse"
                                    @click.prevent="changeHouseOwner(scope.row)"
                            >
                                过户
                            </el-button>

                        </template>
                    </el-table-column>
                </el-table>
                <el-row>
                    <el-pagination
                            background
                            style="margin-top: 50px"
                            layout="prev, pager, next"
                            :page-size="15"
                            @current-change="handleCurrentChange"
                            :total="totalNum">
                    </el-pagination>
                </el-row>
            </el-card>
        </div>
        <el-dialog
                title="绑定业主"
                :visible.sync="dialogVisibleBindOwner"
                style="vertical-align: middle"
                :before-close="handleClose"
                width="30%"
        >
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                     element-loading-text="绑定中，请稍后..." v-loading="bindLoading"
                     size="small">
                <el-form-item label="姓名" prop="ownerName">
                    <el-input v-model="ruleForm.ownerName" size="small" style="width: 80%"></el-input>
                </el-form-item>

                <el-form-item label="业主类型" prop="ownerType">
                    <el-select v-model="ruleForm.ownerType" placeholder="请选择">
                        <el-option
                                v-for="item in owneroptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="证件号" prop="idCard">
                    <el-input v-model="ruleForm.idCard"   placeholder="请输入身份证号或统一社会信用代码"  size="small" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="ruleForm.phone" size="small" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="购房款" prop="houseMoney" v-if="ruleForm.TYPE=='387175058042257408'">
                    <el-input v-model="ruleForm.housPurchase" size="small" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">立即绑定</el-button>
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!--过户展示业主信息的弹框-->
        <el-dialog
                title="户室过户"
                :visible.sync="dialogVisibleChangeOwner"
                style="vertical-align: middle"
                width="70%"

        >
            <template>
                <div class="search" style="height: 80px;margin-top: -30px;">
                    <el-form label-width="90px" :inline="true">
                        <el-form-item label="业主姓名">
                            <el-input v-model="formOwner.ownerName" placeholder="请输入搜索值" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item label="身份证号码">
                            <el-input v-model="formOwner.idCard" placeholder="请输入搜索值" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话">
                            <el-input v-model="formOwner.phone" placeholder="请输入搜索值" size="mini"></el-input>
                        </el-form-item>
                        <button @click.prevent="getOwnerData()" style="cursor: pointer">搜索</button>
                    </el-form>
                </div>
                <el-card>
                    <el-table
                            :data="ownerData"
                            style="width: 100%"
                            v-loading="dialogVisibleChangeOwnerLoading"
                            :element-loading-text="changeOwnerText"
                    >
                        <el-table-column
                                prop="ownerName"
                                label="业主姓名"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="idCard"
                                label="身份证号码"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="phone"
                                label="联系电话">
                        </el-table-column>
                        <el-table-column
                                fixed="right"
                                label="操作"
                        >
                            <template slot-scope="scope">
                                <el-button
                                        type="text"
                                        size="mini"
                                        @click.prevent="guohu(scope.row)"
                                >
                                    选择
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <el-row>
                    <el-pagination
                            background
                            style="margin-top: 50px"
                            layout="prev, pager, next"
                            :page-size="6"
                            @current-change="handleCurrentChangeOwnerData"
                            :total="ownerTotalNum">
                    </el-pagination>
                </el-row>

            </template>
        </el-dialog>


    </div>
</template>

<script>
    import commonTitle from "../table/commonTitle";

    export default {
        name: "houseManage",
        components: {
            commonTitle
        },
        data() {
            let validID = (rule, value, callback) => {
                if (value == '' || value == undefined) {
                    callback(new Error('请输入身份证号码'))
                } else {
                    let reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
                    if (!reg.test(value)) {
                        callback(new Error('身份证号码格式不正确'))
                    } else {
                        callback()
                    }
                }

            }
            //前台校验手机号码
            let validPhone = (rule, value, callback) => {
                if (value == '' || value == undefined) {
                    callback(new Error('请输入手机号码'))
                } else {
                    let reg = /^1[3456789]\d{9}$/
                    if (!reg.test(value)) {
                        callback(new Error('手机号码格式不正确'))
                    } else {
                        callback()
                    }
                }
            }

            return {
                owneroptions: [{
                    value: '1',
                    label: '个人'
                }, {
                    value: '2',
                    label: '单位'
                }],
                houseMoney: '',
                options: [{
                    value: 'villageName',
                    label: '小区名称'
                }, {
                    value: 'towerNum',
                    label: '楼幢号'
                }, {
                    value: 'unitName',
                    label: '单元名称'
                }, {
                    value: 'houseNum',
                    label: '户室号'
                }, {
                    value: 'ownerName',
                    label: '业主姓名'
                }],
                tableData: [],//表格数据集
                totalNum: 15,
                ownerTotalNum: 6,
                form: {
                    name: "",
                    region: '',
                },
                searchKey: '',//搜索条件key
                searchValue: '',//搜索的value
                loading: false,
                url: this.$Config.base_server,
                dialogVisibleBindOwner: false,
                dialogVisibleChangeOwner: false,//过户是否展示业主信息的弹框
                dialogVisibleChangeOwnerLoading: false,
                isShowChangeHouse: '',
                ruleForm: {
                    ownerName: '',
                    idCard: '',
                    phone: '',
                    housPurchase:'',
                    oldhousPurchase:'',
                    houseId: '',
                    TYPE:''
                }, rules: {
                    ownerName: [
                        {required: true, message: '请输入业主姓名', trigger: 'change'}
                    ],
                    idCard: [
                        {required: true, trigger: 'change'}
                        // {validator: validID, trigger: 'change'}
                    ],
                    phone: [
                        {required: true, trigger: 'change'},
                        {validator: validPhone, trigger: 'change'}
                    ],
                }, data: {
                    access_token: localStorage.getItem("token"),
                    limit: 15,
                }, ownerData: [],//过户业主列表集合
                changeHouseId: '',//需要过户的户室id
                formOwner: {
                    ownerName: '',
                    idCard: '',
                    phone: ''
                }, bindLoading: false,//绑定业主弹框动态等待效果
                changeOwnerText: '拼命加载中'
            };
        }, mounted() {
            this.common();
            //是否展示这个按钮
            let userType = JSON.parse(localStorage.getItem("user")).type;
            if (userType == 1) {
                this.isShowChangeHouse = false;
            } else {
                this.isShowChangeHouse = true;
            }
        }, methods: {
            common(currentPage = 0) {
                //初始化单元数据
                const that = this;
                that.loading = true;

                that.data.page = currentPage;
                that.data.searchKey = that.searchKey;
                that.data.searchValue = that.searchValue;
                $.ajax({
                    url: that.url + "/api-public/opehouse/findAllManagerHouse",
                    data: that.data,
                    contentType: "application/json",
                    type: "get",
                    success: function (res) {
                        if (res.code == 0) {
                            that.loading = false;
                            that.totalNum = res.count;
                            that.tableData = res.data;
                        }
                    },
                    error: function (res) {
                        that.loading = false;
                    }
                });
            },
            handleSizeChange(val) {
                console.log('每页${val}条');
                this.pagesize = val;
            },
            handleCurrentChange(val) {
                console.log('当前页' + val);
                this.currpage = val;
                this.common(val);
            }, formatterHouseType(row) {
                //格式化户室类型
                if (row.houseType == 0) {
                    return "商品房";
                }
                if (row.houseType == 1) {
                    return "公租房";
                }
            }, bindHouseOwner(row) {
                //绑定业主
                this.ruleForm.houseId = row.houseId;
                this.ruleForm.ownerName = row.ownerName;
                this.ruleForm.idCard = row.idCard;
                this.ruleForm.phone = row.phone;
                this.ruleForm.housPurchase = row.housPurchase;
                this.ruleForm.oldhousPurchase = row.housPurchase;
                this.ruleForm.TYPE = row.stan2;
                this.dialogVisibleBindOwner = true;
            }, submitForm(formName) {
                const _this = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {

                        this.$confirm('确定要绑定该用户信息, 是否继续?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            //请求绑定业主的方法
                            _this.bindLoading = true;
                            $.ajax({
                                url: _this.url + "/api-public/buildownerHouse?access_token=" + localStorage.getItem("token"),
                                data: JSON.stringify(_this.ruleForm),
                                contentType: 'application/json',
                                type: 'post',
                                success: function (result) {
                                    _this.bindLoading = false;
                                    if (result.resp_code == 0) {
                                        //关闭绑定弹框
                                        _this.dialogVisibleBindOwner = false;
                                        _this.$refs['ruleForm'].resetFields();
                                        _this.common();
                                        _this.$message({
                                            type: "success",
                                            message: result.resp_msg
                                        });
                                    }
                                    if (result.resp_code == 1) {
                                        _this.$message({
                                            type: "error",
                                            message: result.resp_msg,
                                            dangerouslyUseHTMLString: true
                                        });
                                    }
                                },error:function(){
                                   _this.bindLoading = false;  
                                }
                            });
                        }).catch(() => {
                            _this.bindLoading = false;
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }, formatterHouseState(row) {
                //格式化房屋状态
                if (row.state == 0) {
                    return "未绑定";
                } else if (row.state == 1) {
                    return "已绑定";
                } else if (row.state == 2) {
                    return "已激活";
                }
            }, resetForm() {
                //重置
                this.$refs['ruleForm'].resetFields();
            }, handleClose() {
                this.$refs['ruleForm'].resetFields();
                this.dialogVisibleBindOwner = false;
            }, changeHouseOwner(row) {
                //过户
                //打开弹框
                this.changeHouseId = row.houseId;
                this.dialogVisibleChangeOwner = true;
                this.getOwnerData();
            }, handleCurrentChangeOwnerData(val) {
                //业主数据翻页
                this.getOwnerData(val);
            }, getOwnerData(cur = 0) {
                //请求业主数据
                //请求后台数据
                const that = this;
                that.dialogVisibleChangeOwnerLoading = true;
                const data = {
                    enabled: 1,
                    page: cur,
                    limit: 6,
                    ownerName: that.formOwner.ownerName,
                    idCard: that.formOwner.idCard,
                    phone: that.formOwner.phone
                }
                $.ajax({
                    url: this.url + "/api-base/opeowner/list?access_token=" + localStorage.getItem("token"),
                    data: data,
                    type: 'get',
                    success: function (result) {
                        that.dialogVisibleChangeOwnerLoading = false;
                        that.ownerData = result.data;
                        that.ownerTotalNum = result.count;
                    }
                });
            }, guohu(row) {
                const tip = "确定过户给" + row.ownerName + "吗?";
                const that = this;
                this.$confirm(tip, {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.dialogVisibleChangeOwnerLoading = true;
                    that.changeOwnerText="过户中,请稍后...";
                    //调用过户方法
                    const data = {
                        ownerId: row.ownerId,
                        houseId: that.changeHouseId
                    };
                    $.ajax({
                        url: that.url + "/api-public/changeOwnerHouse?access_token=" + localStorage.getItem("token"),
                        data: JSON.stringify(data),
                        type: 'post',
                        contentType: 'application/json',
                        success: function (result) {
                            that.dialogVisibleChangeOwnerLoading=false;
                            if (result.resp_code == 0) {
                                that.$message({
                                    type: "success",
                                    message: result.resp_msg,
                                    duration: 1500,
                                    onClose: function () {
                                        that.common();
                                        that.dialogVisibleChangeOwner = false;
                                    }
                                });
                            }
                            if (result.resp_code == 1) {
                                that.$message({
                                    type: "error",
                                    message: result.resp_msg
                                });
                            }
                        }
                    });

                }).catch(() => {
                    that.dialogVisibleChangeOwner = false;
                });
            }
        }
    }
</script>

<style scoped>
    .btn {
        float: right;
        transform: translateY(-34px);
    }
</style>